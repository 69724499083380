<template>
  <div
    class="rich_media_content js_underline_content autoTypeSetting24psection"
    id="js_content_9"
    style=""
  >
    <h1
      class="rich_media_title"
      id="activity-name"
      style="text-align: center; margin-bottom: 50px"
    >
      「医院孕产妇实我风险核验系统」入围2024中安协“AI+安防”典型案例
    </h1>
    <section style="font-size: 16px; color: rgb(62, 62, 62); visibility: visible">
      <section style="transform-style: preserve-3d; visibility: visible">
        <section
          style="
            text-align: left;
            justify-content: flex-start;
            display: flex;
            flex-flow: row;
            margin-top: 10px;
            margin-bottom: -10px;
            transform-style: preserve-3d;
            visibility: visible;
          "
        >
          <section
            style="
              display: inline-block;
              width: auto;
              vertical-align: middle;
              align-self: center;
              flex: 0 0 auto;
              min-width: 5%;
              height: auto;
              padding-left: 6px;
              transform-style: preserve-3d;
              perspective: 50px;
              visibility: visible;
            "
          >
            <section
              style="transform: rotateZ(10deg) rotateY(25deg); visibility: visible"
            >
              <section
                style="
                  justify-content: flex-start;
                  display: flex;
                  flex-flow: row;
                  visibility: visible;
                "
              >
                <section
                  style="
                    display: inline-block;
                    width: auto;
                    vertical-align: top;
                    align-self: flex-start;
                    flex: 0 0 auto;
                    background-image: linear-gradient(
                      90deg,
                      rgb(38, 139, 243) 0%,
                      rgb(202, 252, 255) 100%
                    );
                    min-width: 5%;
                    height: auto;
                    border-radius: 4px;
                    overflow: hidden;
                    padding-right: 9px;
                    padding-left: 5px;
                    visibility: visible;
                  "
                  class="news_bg"
                >
                  <section
                    style="
                      text-align: justify;
                      color: rgb(255, 255, 255);
                      font-size: 9px;
                      visibility: visible;
                    "
                  >
                    <p style="visibility: visible">
                      <strong style="visibility: visible">NEWS</strong>
                    </p>
                  </section>
                </section>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section
        style="
          text-align: left;
          justify-content: flex-start;
          display: flex;
          flex-flow: row;
          margin-bottom: 10px;
          visibility: visible;
        "
      >
        <section
          style="
            display: inline-block;
            width: auto;
            vertical-align: top;
            align-self: flex-start;
            flex: 100 100 0%;
            background-color: rgb(238, 243, 255);
            height: auto;
            padding: 14px;
            visibility: visible;
          "
        >
          <section
            style="
              justify-content: flex-start;
              display: flex;
              flex-flow: row;
              margin-top: 10px;
              visibility: visible;
            "
          >
            <section
              style="
                display: inline-block;
                width: 100%;
                vertical-align: top;
                align-self: flex-start;
                flex: 0 0 auto;
                background-color: rgb(255, 255, 255);
                padding: 14px;
                box-shadow: rgb(225, 233, 246) 0px 0px 8px 0px;
                visibility: visible;
              "
            >
              <section style="text-align: justify; visibility: visible">
                <p style="visibility: visible">
                  2024年10月23日，由中国安全防范产品行业协会主办，中国安全防范产品行业协会专家委员会承办的2024(第四届)中国安防人工智能创新论坛在北京中国国际展览中心成功召开，四川中京安民科技有限公司「医院孕产妇实我风险核验系统」入围“AI+安防”典型案例。
                </p>
              </section>
            </section>
          </section>
        </section>
      </section>
      <section
        style="
          text-align: center;
          justify-content: center;
          display: flex;
          flex-flow: row;
          margin-top: 10px;
          margin-bottom: 10px;
          visibility: visible;
        "
      >
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 100 100 0%;
            height: auto;
            padding-right: 5px;
            visibility: visible;
          "
        >
          <section style="margin-top: 0.5em; margin-bottom: 0.5em; visibility: visible">
            <section
              style="
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                height: 1px;
                visibility: visible;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="
                  float: left;
                  line-height: 0;
                  width: 0px;
                  vertical-align: top;
                  visibility: visible;
                "
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            background-image: linear-gradient(
              140deg,
              rgb(50, 151, 248) 0%,
              rgb(28, 96, 248) 34%,
              rgb(30, 101, 248) 66%,
              rgb(50, 151, 248) 100%
            );
            border-style: solid;
            border-width: 2px;
            border-color: rgb(255, 255, 255);
            border-radius: 50px;
            overflow: hidden;
            padding: 10px 25px;
            min-width: 5%;
            height: auto;
            z-index: 1;
            visibility: visible;
          "
          class="title_bg"
        >
          <section
            style="
              text-align: left;
              justify-content: flex-start;
              display: flex;
              flex-flow: row;
              visibility: visible;
            "
          >
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 0 0 auto;
                min-width: 5%;
                height: auto;
                visibility: visible;
              "
            >
              <section style="text-align: right; visibility: visible">
                <section
                  style="
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    vertical-align: top;
                    overflow: hidden;
                    border-width: 0px;
                    border-radius: 100%;
                    border-style: none;
                    border-color: rgb(62, 62, 62);
                    background-color: rgb(255, 255, 255);
                    visibility: visible;
                  "
                >
                  <section
                    style="
                      text-align: center;
                      margin-top: 5px;
                      margin-bottom: 10px;
                      line-height: 0;
                      visibility: visible;
                    "
                  >
                    <section
                      style="
                        vertical-align: middle;
                        display: inline-block;
                        line-height: 0;
                        width: 65%;
                        height: auto;
                        visibility: visible;
                      "
                    >
                      <img
                        data-imgfileid="100000454"
                        data-ratio="1"
                        data-s="300,640"
                        data-type="gif"
                        data-w="640"
                        style="
                          vertical-align: middle;
                          width: 16.25px !important;
                          height: auto !important;
                          visibility: visible !important;
                        "
                        data-original-style="vertical-align: middle;width: 100%;"
                        data-index="1"
                        src="@/assets/images/news/09/01.gif"
                        class=""
                        _width="100%"
                        crossorigin="anonymous"
                        alt="图片"
                        data-fail="0"
                      />
                    </section>
                  </section>
                  <section
                    style="
                      text-align: center;
                      justify-content: center;
                      display: flex;
                      flex-flow: row;
                      margin-top: 10px;
                      margin-bottom: -10px;
                      visibility: visible;
                    "
                  >
                    <section
                      style="
                        display: inline-block;
                        vertical-align: top;
                        width: 32px;
                        align-self: flex-start;
                        flex: 0 0 auto;
                        height: auto;
                        line-height: 0;
                        visibility: visible;
                      "
                    >
                      <section style="transform: rotateZ(343deg); visibility: visible">
                        <section style="visibility: visible">
                          <section
                            style="
                              display: inline-block;
                              width: 7px;
                              height: 18px;
                              vertical-align: top;
                              overflow: hidden;
                              background-color: rgb(255, 236, 89);
                              border-radius: 75px;
                              visibility: visible;
                            "
                          >
                            <section style="text-align: justify; visibility: visible">
                              <p style="visibility: visible">
                                <br style="visibility: visible" />
                              </p>
                            </section>
                          </section>
                        </section>
                      </section>
                    </section>
                    <section
                      style="
                        display: inline-block;
                        vertical-align: top;
                        width: 32px;
                        align-self: flex-start;
                        flex: 0 0 auto;
                        height: auto;
                        line-height: 0;
                        visibility: visible;
                      "
                    >
                      <section style="transform: rotateZ(17deg); visibility: visible">
                        <section style="visibility: visible">
                          <section
                            style="
                              display: inline-block;
                              width: 7px;
                              height: 18px;
                              vertical-align: top;
                              overflow: hidden;
                              background-color: rgb(255, 236, 89);
                              border-radius: 75px;
                              visibility: visible;
                            "
                          >
                            <section style="text-align: justify; visibility: visible">
                              <p style="visibility: visible">
                                <br style="visibility: visible" />
                              </p>
                            </section>
                          </section>
                        </section>
                      </section>
                    </section>
                  </section>
                  <section
                    style="
                      text-align: center;
                      justify-content: center;
                      display: flex;
                      flex-flow: row;
                      margin-bottom: 10px;
                      visibility: visible;
                    "
                  >
                    <section
                      style="
                        display: inline-block;
                        width: auto;
                        vertical-align: top;
                        align-self: flex-start;
                        flex: 0 0 auto;
                        background-color: rgb(180, 210, 6);
                        min-width: 5%;
                        height: auto;
                        padding: 9px 16px;
                        border-radius: 99%;
                        overflow: hidden;
                        visibility: visible;
                      "
                    >
                      <section style="margin-top: 6px; visibility: visible">
                        <section
                          style="
                            text-align: justify;
                            color: rgb(255, 255, 255);
                            visibility: visible;
                          "
                        >
                          <p style="visibility: visible">
                            <strong style="visibility: visible">开学第一课</strong>
                          </p>
                        </section>
                      </section>
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 100 100 0%;
                height: auto;
                padding-left: 10px;
                visibility: visible;
              "
            >
              <section
                style="
                  text-align: justify;
                  letter-spacing: 2px;
                  color: rgb(255, 255, 255);
                  visibility: visible;
                "
              >
                <p style="visibility: visible">1.案例背景</p>
              </section>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 100 100 0%;
            height: auto;
            padding-left: 5px;
            visibility: visible;
          "
        >
          <section style="margin-top: 0.5em; margin-bottom: 0.5em; visibility: visible">
            <section
              style="
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                height: 1px;
                visibility: visible;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="
                  float: left;
                  line-height: 0;
                  width: 0px;
                  vertical-align: top;
                  visibility: visible;
                "
              ></svg>
            </section>
          </section>
        </section>
      </section>
      <section
        style="
          text-align: center;
          justify-content: center;
          display: flex;
          flex-flow: row;
          margin-bottom: 10px;
          visibility: visible;
        "
      >
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
            z-index: auto;
            line-height: 0;
            visibility: visible;
          "
        >
          <section style="visibility: visible">
            <section
              style="
                display: inline-block;
                width: 5px;
                height: 5px;
                vertical-align: top;
                overflow: hidden;
                border-width: 0px;
                border-radius: 100%;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                visibility: visible;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="
                  float: left;
                  line-height: 0;
                  width: 0px;
                  vertical-align: top;
                  visibility: visible;
                "
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
            z-index: auto;
            line-height: 0;
            visibility: visible;
          "
        >
          <section style="visibility: visible">
            <section
              style="
                display: inline-block;
                width: 5px;
                height: 5px;
                vertical-align: top;
                overflow: hidden;
                border-width: 0px;
                border-radius: 100%;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                visibility: visible;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="
                  float: left;
                  line-height: 0;
                  width: 0px;
                  vertical-align: top;
                  visibility: visible;
                "
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
            z-index: auto;
            line-height: 0;
            visibility: visible;
          "
        >
          <section style="visibility: visible">
            <section
              style="
                display: inline-block;
                width: 5px;
                height: 5px;
                vertical-align: top;
                overflow: hidden;
                border-width: 0px;
                border-radius: 100%;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                visibility: visible;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="
                  float: left;
                  line-height: 0;
                  width: 0px;
                  vertical-align: top;
                  visibility: visible;
                "
              ></svg>
            </section>
          </section>
        </section>
      </section>
      <section style="visibility: visible">
        <p style="visibility: visible">
          根据全国各地相关文件要求，加强医疗监督执法部门协作，做好孕产妇身份核对，预防和打击代孕、非法采供精（卵）、拐卖妇女儿童等犯罪行为，依法开展母婴保健和规范管理。医疗机构需在相关服务中依法完成身份核对，确保孕产妇实名就医、分娩及办理出生证明。
        </p>
        <p style="visibility: visible"><br style="visibility: visible" /></p>
      </section>
      <section
        style="
          text-align: center;
          justify-content: center;
          display: flex;
          flex-flow: row;
          margin-top: 10px;
          margin-bottom: 10px;
          visibility: visible;
        "
      >
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 100 100 0%;
            height: auto;
            padding-right: 5px;
            visibility: visible;
          "
        >
          <section style="margin-top: 0.5em; margin-bottom: 0.5em; visibility: visible">
            <section
              style="
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                height: 1px;
                visibility: visible;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="
                  float: left;
                  line-height: 0;
                  width: 0px;
                  vertical-align: top;
                  visibility: visible;
                "
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            background-image: linear-gradient(
              140deg,
              rgb(50, 151, 248) 0%,
              rgb(28, 96, 248) 34%,
              rgb(30, 101, 248) 66%,
              rgb(50, 151, 248) 100%
            );
            border-style: solid;
            border-width: 2px;
            border-color: rgb(255, 255, 255);
            border-radius: 50px;
            overflow: hidden;
            box-shadow: rgb(189, 189, 189) 0px 0px 3px 0px,
              rgb(54, 243, 241) 2px 2px 5px 0px inset,
              rgb(8, 63, 186) -2px -2px 5px 0px inset;
            padding: 10px 25px;
            min-width: 5%;
            height: auto;
            z-index: 1;
            visibility: visible;
          "
          class="title_bg"
        >
          <section
            style="
              text-align: left;
              justify-content: flex-start;
              display: flex;
              flex-flow: row;
              visibility: visible;
            "
          >
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 0 0 auto;
                min-width: 5%;
                height: auto;
                visibility: visible;
              "
            >
              <section style="text-align: right; visibility: visible">
                <section
                  style="
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    vertical-align: top;
                    overflow: hidden;
                    border-width: 0px;
                    border-radius: 100%;
                    border-style: none;
                    border-color: rgb(62, 62, 62);
                    background-color: rgb(255, 255, 255);
                    visibility: visible;
                  "
                >
                  <section
                    style="
                      text-align: center;
                      margin-top: 5px;
                      margin-bottom: 10px;
                      line-height: 0;
                      visibility: visible;
                    "
                  >
                    <section
                      style="
                        vertical-align: middle;
                        display: inline-block;
                        line-height: 0;
                        width: 65%;
                        height: auto;
                        visibility: visible;
                      "
                    >
                      <img
                        class="rich_pages wxw-img"
                        data-imgfileid="100000453"
                        data-ratio="1"
                        data-s="300,640"
                        data-w="640"
                        style="
                          vertical-align: middle;
                          width: 16.25px !important;
                          height: auto !important;
                          visibility: visible !important;
                        "
                        data-original-style="vertical-align: middle;width: 100%;"
                        data-index="2"
                        src="@/assets/images/news/09/01.gif"
                        _width="100%"
                        crossorigin="anonymous"
                        alt="图片"
                        data-fail="0"
                      />
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 100 100 0%;
                height: auto;
                padding-left: 10px;
                visibility: visible;
              "
            >
              <section
                style="
                  text-align: justify;
                  letter-spacing: 2px;
                  color: rgb(255, 255, 255);
                  visibility: visible;
                "
              >
                <p style="visibility: visible">2.概述</p>
              </section>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 100 100 0%;
            height: auto;
            padding-left: 5px;
            visibility: visible;
          "
        >
          <section style="margin-top: 0.5em; margin-bottom: 0.5em; visibility: visible">
            <section
              style="
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                height: 1px;
                visibility: visible;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="
                  float: left;
                  line-height: 0;
                  width: 0px;
                  vertical-align: top;
                  visibility: visible;
                "
              ></svg>
            </section>
          </section>
        </section>
      </section>
      <section
        style="
          text-align: center;
          justify-content: center;
          display: flex;
          flex-flow: row;
          margin-bottom: 10px;
          visibility: visible;
        "
      >
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
            z-index: auto;
            line-height: 0;
            visibility: visible;
          "
        >
          <section style="visibility: visible">
            <section
              style="
                display: inline-block;
                width: 5px;
                height: 5px;
                vertical-align: top;
                overflow: hidden;
                border-width: 0px;
                border-radius: 100%;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                visibility: visible;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="
                  float: left;
                  line-height: 0;
                  width: 0px;
                  vertical-align: top;
                  visibility: visible;
                "
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
            z-index: auto;
            line-height: 0;
            visibility: visible;
          "
        >
          <section style="visibility: visible">
            <section
              style="
                display: inline-block;
                width: 5px;
                height: 5px;
                vertical-align: top;
                overflow: hidden;
                border-width: 0px;
                border-radius: 100%;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                visibility: visible;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="
                  float: left;
                  line-height: 0;
                  width: 0px;
                  vertical-align: top;
                  visibility: visible;
                "
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
            z-index: auto;
            line-height: 0;
            visibility: visible;
          "
        >
          <section style="visibility: visible">
            <section
              style="
                display: inline-block;
                width: 5px;
                height: 5px;
                vertical-align: top;
                overflow: hidden;
                border-width: 0px;
                border-radius: 100%;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                visibility: visible;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="
                  float: left;
                  line-height: 0;
                  width: 0px;
                  vertical-align: top;
                  visibility: visible;
                "
              ></svg>
            </section>
          </section>
        </section>
      </section>
      <section style="visibility: visible">
        <p style="visibility: visible">
          项目建设包括实我风险核验系统及设备配备。在北京协和医院妇产科住院、分娩以及办理出生医学证明三个环节部署核验设备，孕产妇通过刷证或刷码及人脸核验的方式，快速核验；核验结果通过设备端实时向工作人员反馈；所有人员核验信息通过应用系统进行汇聚。
        </p>
        <p style="visibility: visible">
          系统充分利用人工智能、物联网、云计算等新技术，真正做到人员、证件、照片三者统一，实现“实名实人就医、核验留痕、人像对应、无证核验、一人一档、多维分析”等功能。
        </p>
      </section>
      <section
        style="
          text-align: center;
          justify-content: center;
          display: flex;
          flex-flow: row;
          margin-top: 10px;
          margin-bottom: 10px;
          visibility: visible;
        "
      >
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 100 100 0%;
            height: auto;
            padding-right: 5px;
            visibility: visible;
          "
        >
          <section style="margin-top: 0.5em; margin-bottom: 0.5em; visibility: visible">
            <section
              style="
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                height: 1px;
                visibility: visible;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="
                  float: left;
                  line-height: 0;
                  width: 0px;
                  vertical-align: top;
                  visibility: visible;
                "
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block1;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            background-image: linear-gradient(
              140deg,
              rgb(50, 151, 248) 0%,
              rgb(28, 96, 248) 34%,
              rgb(30, 101, 248) 66%,
              rgb(50, 151, 248) 100%
            );
            border-style: solid;
            border-width: 2px;
            border-color: rgb(255, 255, 255);
            border-radius: 50px;
            overflow: hidden;
            box-shadow: rgb(189, 189, 189) 0px 0px 3px 0px,
              rgb(54, 243, 241) 2px 2px 5px 0px inset,
              rgb(8, 63, 186) -2px -2px 5px 0px inset;
            padding: 10px 25px;
            min-width: 5%;
            height: auto;
            z-index: 1;
            visibility: visible;
          "
          class="title_bg"
        >
          <section
            style="
              text-align: left;
              justify-content: flex-start;
              display: flex;
              flex-flow: row;
              visibility: visible;
            "
          >
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 0 0 auto;
                min-width: 5%;
                height: auto;
                visibility: visible;
              "
            >
              <section style="text-align: right; visibility: visible">
                <section
                  style="
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    vertical-align: top;
                    overflow: hidden;
                    border-width: 0px;
                    border-radius: 100%;
                    border-style: none;
                    border-color: rgb(62, 62, 62);
                    background-color: rgb(255, 255, 255);
                    visibility: visible;
                  "
                >
                  <section
                    style="
                      text-align: center;
                      margin-top: 5px;
                      margin-bottom: 10px;
                      line-height: 0;
                      visibility: visible;
                    "
                  >
                    <section
                      style="
                        vertical-align: middle;
                        display: inline-block;
                        line-height: 0;
                        width: 65%;
                        height: auto;
                        visibility: visible;
                      "
                    >
                      <img
                        data-imgfileid="100000452"
                        data-ratio="1"
                        data-s="300,640"
                        data-w="640"
                        style="
                          vertical-align: middle;
                          width: 16.25px !important;
                          height: auto !important;
                          visibility: visible !important;
                        "
                        data-original-style="vertical-align: middle;width: 100%;"
                        data-index="3"
                        src="@/assets/images/news/09/01.gif"
                        class=""
                        _width="100%"
                        crossorigin="anonymous"
                        alt="图片"
                        data-fail="0"
                      />
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 100 100 0%;
                height: auto;
                padding-left: 10px;
                visibility: visible;
              "
            >
              <section
                style="text-align: justify; letter-spacing: 2px; visibility: visible"
              >
                <p style="visibility: visible">
                  <span style="color: rgb(255, 255, 255); visibility: visible"
                    ><strong style="visibility: visible"
                      ><span style="font-size: 18px; visibility: visible"
                        >3.案例流程</span
                      ></strong
                    ></span
                  ><br style="visibility: visible" />
                </p>
              </section>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 100 100 0%;
            height: auto;
            padding-left: 5px;
            visibility: visible;
          "
        >
          <section style="margin-top: 0.5em; margin-bottom: 0.5em; visibility: visible">
            <section
              style="
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                height: 1px;
                visibility: visible;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="
                  float: left;
                  line-height: 0;
                  width: 0px;
                  vertical-align: top;
                  visibility: visible;
                "
              ></svg>
            </section>
          </section>
        </section>
      </section>
      <section
        style="
          text-align: center;
          justify-content: center;
          display: flex;
          flex-flow: row;
          margin-bottom: 10px;
          visibility: visible;
        "
      >
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
            z-index: auto;
            line-height: 0;
            visibility: visible;
          "
        >
          <section style="visibility: visible">
            <section
              style="
                display: inline-block;
                width: 5px;
                height: 5px;
                vertical-align: top;
                overflow: hidden;
                border-width: 0px;
                border-radius: 100%;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                visibility: visible;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="
                  float: left;
                  line-height: 0;
                  width: 0px;
                  vertical-align: top;
                  visibility: visible;
                "
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
            z-index: auto;
            line-height: 0;
            visibility: visible;
          "
        >
          <section style="visibility: visible">
            <section
              style="
                display: inline-block;
                width: 5px;
                height: 5px;
                vertical-align: top;
                overflow: hidden;
                border-width: 0px;
                border-radius: 100%;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                visibility: visible;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="
                  float: left;
                  line-height: 0;
                  width: 0px;
                  vertical-align: top;
                  visibility: visible;
                "
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
            z-index: auto;
            line-height: 0;
            visibility: visible;
          "
        >
          <section style="visibility: visible">
            <section
              style="
                display: inline-block;
                width: 5px;
                height: 5px;
                vertical-align: top;
                overflow: hidden;
                border-width: 0px;
                border-radius: 100%;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                visibility: visible;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="
                  float: left;
                  line-height: 0;
                  width: 0px;
                  vertical-align: top;
                  visibility: visible;
                "
              ></svg>
            </section>
          </section>
        </section>
      </section>
      <section
        style="
          line-height: 0;
          visibility: visible;
          display: flex;
          justify-content: center;
        "
      >
        <section
          style="
            vertical-align: middle;
            display: inline-block;
            line-height: 0;
            visibility: visible;
          "
        >
          <img
            class="rich_pages wxw-img"
            data-cropselx1="0"
            data-cropselx2="578"
            data-cropsely1="0"
            data-cropsely2="154"
            data-imgfileid="100000451"
            data-ratio="0.26673040152963673"
            data-s="300,640"
            data-w="1046"
            style="
              vertical-align: middle;
              width: 100% !important;
              height: auto !important;
              visibility: visible !important;
            "
            data-original-style="vertical-align: middle;width: 578px;height: 154px;"
            data-index="4"
            src="@/assets/images/news/09/02.webp"
            _width="578px"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </section>
      </section>
      <section
        style="
          text-align: center;
          justify-content: center;
          display: flex;
          flex-flow: row;
          margin-top: 10px;
          margin-bottom: 10px;
        "
      >
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 100 100 0%;
            height: auto;
            padding-right: 5px;
          "
        >
          <section style="margin-top: 0.5em; margin-bottom: 0.5em">
            <section
              style="
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                height: 1px;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="float: left; line-height: 0; width: 0; vertical-align: top"
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            background-image: linear-gradient(
              140deg,
              rgb(50, 151, 248) 0%,
              rgb(28, 96, 248) 34%,
              rgb(30, 101, 248) 66%,
              rgb(50, 151, 248) 100%
            );
            border-style: solid;
            border-width: 2px;
            border-color: rgb(255, 255, 255);
            border-radius: 50px;
            overflow: hidden;
            box-shadow: rgb(189, 189, 189) 0px 0px 3px 0px,
              rgb(54, 243, 241) 2px 2px 5px 0px inset,
              rgb(8, 63, 186) -2px -2px 5px 0px inset;
            padding: 10px 25px;
            min-width: 5%;
            height: auto;
            z-index: 1;
          "
          class="title_bg"
        >
          <section
            style="
              text-align: left;
              justify-content: flex-start;
              display: flex;
              flex-flow: row;
            "
          >
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 0 0 auto;
                min-width: 5%;
                height: auto;
              "
            >
              <section style="text-align: right">
                <section
                  style="
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    vertical-align: top;
                    overflow: hidden;
                    border-width: 0px;
                    border-radius: 100%;
                    border-style: none;
                    border-color: rgb(62, 62, 62);
                    background-color: rgb(255, 255, 255);
                  "
                >
                  <section
                    style="
                      text-align: center;
                      margin-top: 5px;
                      margin-bottom: 10px;
                      line-height: 0;
                    "
                  >
                    <section
                      style="
                        vertical-align: middle;
                        display: inline-block;
                        line-height: 0;
                        width: 65%;
                        height: auto;
                      "
                    >
                      <img
                        class="rich_pages wxw-img"
                        data-imgfileid="100000455"
                        data-ratio="1"
                        data-s="300,640"
                        data-w="640"
                        style="
                          vertical-align: middle;
                          width: 16.25px !important;
                          height: auto !important;
                          visibility: visible !important;
                        "
                        data-original-style="vertical-align: middle;width: 100%;"
                        data-index="5"
                        src="@/assets/images/news/09/01.gif"
                        _width="100%"
                        crossorigin="anonymous"
                        alt="图片"
                        data-fail="0"
                      />
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 100 100 0%;
                height: auto;
                padding-left: 10px;
              "
            >
              <section style="text-align: justify; letter-spacing: 2px">
                <p style="">
                  <span style="color: rgb(255, 255, 255)"
                    ><strong
                      ><span style="font-size: 18px">4.主要功能</span></strong
                    ></span
                  ><br />
                </p>
              </section>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 100 100 0%;
            height: auto;
            padding-left: 5px;
          "
        >
          <section style="margin-top: 0.5em; margin-bottom: 0.5em">
            <section
              style="
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                height: 1px;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="float: left; line-height: 0; width: 0; vertical-align: top"
              ></svg>
            </section>
          </section>
        </section>
      </section>
      <section
        style="
          text-align: center;
          justify-content: center;
          display: flex;
          flex-flow: row;
          margin-bottom: 10px;
        "
      >
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
            z-index: auto;
            line-height: 0;
          "
        >
          <section style="">
            <section
              style="
                display: inline-block;
                width: 5px;
                height: 5px;
                vertical-align: top;
                overflow: hidden;
                border-width: 0px;
                border-radius: 100%;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="float: left; line-height: 0; width: 0; vertical-align: top"
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
            z-index: auto;
            line-height: 0;
          "
        >
          <section style="">
            <section
              style="
                display: inline-block;
                width: 5px;
                height: 5px;
                vertical-align: top;
                overflow: hidden;
                border-width: 0px;
                border-radius: 100%;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="float: left; line-height: 0; width: 0; vertical-align: top"
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
            z-index: auto;
            line-height: 0;
          "
        >
          <section style="">
            <section
              style="
                display: inline-block;
                width: 5px;
                height: 5px;
                vertical-align: top;
                overflow: hidden;
                border-width: 0px;
                border-radius: 100%;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="float: left; line-height: 0; width: 0; vertical-align: top"
              ></svg>
            </section>
          </section>
        </section>
      </section>
      <section>
        <p style="">
          （一）居民身份证核验：读取居民身份证内个人身份信息，并抓拍现场实时活体人脸图像信息，实现人证同一性验证。
        </p>
        <p style="">
          （二）境外人员身份信息核验：读取港澳台通行证、护照内个人身份信息，并抓拍现场实时活体人脸图像信息，实现人证同一性验证。
        </p>
        <p style="">
          <span style="color: rgb(0, 82, 255)"
            ><strong
              >（三）无证核验：通过“实我认证”小程序生成个人身份实我码，未带身份证人员可刷码比对现场人像，实现人证同一性验证。</strong
            ></span
          >
        </p>
        <p style="">
          <span style="color: rgb(0, 82, 255)"
            ><strong
              >（四）无证复核：针对无证人员，通过手动录入的方式采集人员身份信息，实现对人员身份信息复核。</strong
            ></span
          >
        </p>
        <p style="">
          <span style="color: rgb(0, 82, 255)"
            ><strong
              >（五）内网复核：发生孕产妇以他人名义入院、拒绝进行身份核验、人脸识别不通过的情形，可通过现场抓拍的人脸，将数据传入公安内网平台并确认该孕产妇身份信息，实现执法联动。</strong
            ></span
          >
        </p>
        <p style="">
          <span style="color: rgb(0, 82, 255)"
            ><strong
              >（六）风险评估：可根据需要，对孕产妇等相关人员进行风险评估。</strong
            ></span
          >
        </p>
        <p style="">
          <span style="color: rgb(0, 82, 255)"
            ><strong
              >（七）公安部门联动：系统提供双网双平台，可将孕产妇核验数据同步到公安系统。</strong
            ></span
          >
        </p>
        <p style="">（八）数据安全：采用国密算法，对接医院HIS系统，避免数据外泄风险。</p>
        <p style="">
          （九）数据管理：提供孕产妇实时核验数据，提供按时间、姓名、身份证号等多种方式查询。
        </p>
      </section>
      <section
        style="
          text-align: center;
          justify-content: center;
          display: flex;
          flex-flow: row;
          margin-top: 10px;
          margin-bottom: 10px;
        "
      >
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 100 100 0%;
            height: auto;
            padding-right: 5px;
          "
        >
          <section style="margin-top: 0.5em; margin-bottom: 0.5em">
            <section
              style="
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                height: 1px;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="float: left; line-height: 0; width: 0; vertical-align: top"
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            background-image: linear-gradient(
              140deg,
              rgb(50, 151, 248) 0%,
              rgb(28, 96, 248) 34%,
              rgb(30, 101, 248) 66%,
              rgb(50, 151, 248) 100%
            );
            border-style: solid;
            border-width: 2px;
            border-color: rgb(255, 255, 255);
            border-radius: 50px;
            overflow: hidden;
            box-shadow: rgb(189, 189, 189) 0px 0px 3px 0px,
              rgb(54, 243, 241) 2px 2px 5px 0px inset,
              rgb(8, 63, 186) -2px -2px 5px 0px inset;
            padding: 10px 25px;
            min-width: 5%;
            height: auto;
            z-index: 1;
          "
          class="title_bg"
        >
          <section
            style="
              text-align: left;
              justify-content: flex-start;
              display: flex;
              flex-flow: row;
            "
          >
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 0 0 auto;
                min-width: 5%;
                height: auto;
              "
            >
              <section style="text-align: right">
                <section
                  style="
                    display: inline-block;
                    width: 25px;
                    height: 25px;
                    vertical-align: top;
                    overflow: hidden;
                    border-width: 0px;
                    border-radius: 100%;
                    border-style: none;
                    border-color: rgb(62, 62, 62);
                    background-color: rgb(255, 255, 255);
                  "
                >
                  <section
                    style="
                      text-align: center;
                      margin-top: 5px;
                      margin-bottom: 10px;
                      line-height: 0;
                    "
                  >
                    <section
                      style="
                        vertical-align: middle;
                        display: inline-block;
                        line-height: 0;
                        width: 65%;
                        height: auto;
                      "
                    >
                      <img
                        data-imgfileid="100000459"
                        data-ratio="1"
                        data-s="300,640"
                        data-w="640"
                        style="
                          vertical-align: middle;
                          width: 16.25px !important;
                          height: auto !important;
                          visibility: visible !important;
                        "
                        data-original-style="vertical-align: middle;width: 100%;"
                        data-index="6"
                        src="@/assets/images/news/09/01.gif"
                        class=""
                        _width="100%"
                        crossorigin="anonymous"
                        alt="图片"
                        data-fail="0"
                      />
                    </section>
                  </section>
                </section>
              </section>
            </section>
            <section
              style="
                display: inline-block;
                vertical-align: middle;
                width: auto;
                align-self: center;
                flex: 100 100 0%;
                height: auto;
                padding-left: 10px;
              "
            >
              <section style="text-align: justify; letter-spacing: 2px">
                <p style="">
                  <span style="color: rgb(255, 255, 255)"
                    ><strong
                      ><span style="font-size: 18px">5.行业应用</span></strong
                    ></span
                  >
                </p>
              </section>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 100 100 0%;
            height: auto;
            padding-left: 5px;
          "
        >
          <section style="margin-top: 0.5em; margin-bottom: 0.5em">
            <section
              style="
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
                height: 1px;
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="float: left; line-height: 0; width: 0; vertical-align: top"
              ></svg>
            </section>
          </section>
        </section>
      </section>
      <section
        style="
          text-align: center;
          justify-content: center;
          display: flex;
          flex-flow: row;
          margin-bottom: 10px;
        "
      >
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
            z-index: auto;
            line-height: 0;
          "
        >
          <section style="">
            <section
              style="
                display: inline-block;
                width: 5px;
                height: 5px;
                vertical-align: top;
                overflow: hidden;
                border-width: 0px;
                border-radius: 100%;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="float: left; line-height: 0; width: 0; vertical-align: top"
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
            z-index: auto;
            line-height: 0;
          "
        >
          <section style="">
            <section
              style="
                display: inline-block;
                width: 5px;
                height: 5px;
                vertical-align: top;
                overflow: hidden;
                border-width: 0px;
                border-radius: 100%;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="float: left; line-height: 0; width: 0; vertical-align: top"
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
            z-index: auto;
            line-height: 0;
          "
        >
          <section style="">
            <section
              style="
                display: inline-block;
                width: 5px;
                height: 5px;
                vertical-align: top;
                overflow: hidden;
                border-width: 0px;
                border-radius: 100%;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  140deg,
                  rgb(50, 151, 248) 0%,
                  rgb(28, 96, 248) 34%,
                  rgb(30, 101, 248) 66%,
                  rgb(50, 151, 248) 100%
                );
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="float: left; line-height: 0; width: 0; vertical-align: top"
              ></svg>
            </section>
          </section>
        </section>
      </section>
      <p style="">
        该设备具有广泛的跨行业适用性，除医院孕产妇身份核验、麻精药品管理，可应用于党政机关、中小学、幼儿园、高校、水电气热等重要基础设施、文博、军工单位、国家重点建设工程单位、大型物资储备单位、加油站等多个行业，解决各行业在人员身份核验、安全风险管控中的痛点问题。
      </p>
      <section
        style="
          text-align: center;
          justify-content: center;
          margin: 10px 0%;
          display: flex;
          flex-flow: row;
        "
      >
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: 10%;
            flex: 0 0 auto;
            height: auto;
            align-self: center;
          "
        >
          <section
            style="margin-top: 5px; margin-right: 0%; margin-left: 0%; text-align: left"
          >
            <section style="background-color: rgb(38, 38, 38); height: 1px">
              <svg
                viewBox="0 0 1 1"
                style="float: left; line-height: 0; width: 0; vertical-align: top"
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            line-height: 0;
            min-width: 10%;
            flex: 0 0 auto;
            height: auto;
            margin-left: 24px;
            align-self: center;
          "
        >
          <section style="text-align: left">
            <section
              style="
                display: inline-block;
                vertical-align: top;
                overflow: hidden;
                height: 27px;
                width: 27px;
                border-width: 0px;
                border-radius: 150px;
                border-style: none;
                border-color: rgb(62, 62, 62);
                background-image: linear-gradient(
                  90deg,
                  rgb(38, 139, 243) 0%,
                  rgb(202, 252, 255) 100%
                );
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="float: left; line-height: 0; width: 0; vertical-align: top"
              ></svg>
            </section>
          </section>
          <section
            style="
              text-align: left;
              margin-top: -22px;
              margin-right: 0%;
              margin-left: 0%;
              transform: translate3d(-4px, 0px, 0px);
            "
          >
            <section
              style="
                display: inline-block;
                width: 27px;
                height: 27px;
                vertical-align: top;
                overflow: hidden;
                background-color: rgba(255, 255, 255, 0);
                border-width: 1px;
                border-radius: 150px;
                border-style: solid;
                border-color: rgb(38, 38, 38);
              "
            >
              <svg
                viewBox="0 0 1 1"
                style="float: left; line-height: 0; width: 0; vertical-align: top"
              ></svg>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: 10%;
            flex: 0 0 auto;
            height: auto;
            align-self: center;
          "
        >
          <section
            style="margin-top: 5px; margin-right: 0%; margin-left: 0%; text-align: left"
          >
            <section style="background-color: rgb(38, 38, 38); height: 1px">
              <svg
                viewBox="0 0 1 1"
                style="float: left; line-height: 0; width: 0; vertical-align: top"
              ></svg>
            </section>
          </section>
        </section>
      </section>
      <section
        style="text-align: center; margin-top: 10px; margin-bottom: 10px; line-height: 0"
      >
        <section style="vertical-align: middle; display: inline-block; line-height: 0">
          <img
            class="rich_pages wxw-img"
            data-imgfileid="100000460"
            data-ratio="0.75"
            data-s="300,640"
            data-w="1080"
            style="
              vertical-align: middle;
              width: 100% !important;
              height: auto !important;
              visibility: visible !important;
            "
            data-original-style="vertical-align: middle;width: 100%;"
            data-index="7"
            src="@/assets/images/news/09/03.webp"
            _width="100%"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </section>
      </section>
      <p style="">“AI+安防”案例展示区</p>
      <section
        style="
          text-align: center;
          justify-content: center;
          display: flex;
          flex-flow: row;
          margin-top: 10px;
          margin-bottom: 10px;
        "
      >
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
          "
        >
          <section style="transform: perspective(0px); transform-style: flat">
            <section style="line-height: 0; transform: rotateY(180deg)">
              <section
                style="
                  vertical-align: middle;
                  display: inline-block;
                  line-height: 0;
                  width: 14px;
                  height: auto;
                "
              >
                <img
                  data-imgfileid="100000457"
                  data-ratio="1.3692614770459082"
                  data-s="300,640"
                  data-w="501"
                  style="
                    vertical-align: middle;
                    width: 14px !important;
                    height: auto !important;
                    visibility: visible !important;
                  "
                  data-original-style="vertical-align: middle;width: 100%;"
                  data-index="8"
                  src="@/assets/images/news/09/05.webp"
                  class=""
                  _width="100%"
                  crossorigin="anonymous"
                  alt="图片"
                  data-fail="0"
                />
              </section>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
          "
        >
          <section style="">
            <section
              style="
                display: inline-block;
                width: 34px;
                height: 34px;
                vertical-align: top;
                overflow: hidden;
                background-color: rgb(114, 174, 255);
                border-radius: 322px;
                box-shadow: rgba(255, 255, 255, 0.44) 0px 11px 11px 0px inset;
              "
            >
              <section style="margin-top: 8px; margin-bottom: 10px; line-height: 0">
                <section
                  style="
                    vertical-align: middle;
                    display: inline-block;
                    line-height: 0;
                    width: 17px;
                    height: auto;
                  "
                >
                  <img
                    data-imgfileid="100000458"
                    data-ratio="1.102"
                    data-s="300,640"
                    data-w="500"
                    style="
                      vertical-align: middle;
                      width: 17px !important;
                      height: auto !important;
                      visibility: visible !important;
                    "
                    data-original-style="vertical-align: middle;width: 100%;"
                    data-index="9"
                    src="@/assets/images/news/09/06.webp"
                    class=""
                    _width="100%"
                    crossorigin="anonymous"
                    alt="图片"
                    data-fail="0"
                  />
                </section>
              </section>
              <section style="text-align: justify">
                <p style=""><br /></p>
              </section>
            </section>
          </section>
        </section>
        <section
          style="
            display: inline-block;
            vertical-align: middle;
            width: auto;
            align-self: center;
            flex: 0 0 auto;
            min-width: 5%;
            height: auto;
          "
        >
          <section style="line-height: 0">
            <section
              style="
                vertical-align: middle;
                display: inline-block;
                line-height: 0;
                width: 14px;
                height: auto;
              "
            >
              <img
                data-imgfileid="100000456"
                data-ratio="1.3692614770459082"
                data-s="300,640"
                data-w="501"
                style="
                  vertical-align: middle;
                  width: 14px !important;
                  height: auto !important;
                  visibility: visible !important;
                "
                data-original-style="vertical-align: middle;width: 100%;"
                data-index="10"
                src="@/assets/images/news/09/05.webp"
                class=""
                _width="100%"
                crossorigin="anonymous"
                alt="图片"
                data-fail="0"
              />
            </section>
          </section>
        </section>
      </section>
      <section
        style="text-align: center; margin-top: 10px; margin-bottom: 10px; line-height: 0"
      >
        <section style="vertical-align: middle; display: inline-block; line-height: 0">
          <img
            class="rich_pages wxw-img"
            data-imgfileid="100000462"
            data-ratio="0.6666666666666666"
            data-s="300,640"
            data-w="1080"
            style="
              vertical-align: middle;
              width: 100% !important;
              height: auto !important;
              visibility: visible !important;
            "
            data-original-style="vertical-align: middle;width: 100%;"
            data-index="11"
            src="@/assets/images/news/09/04.webp"
            _width="100%"
            crossorigin="anonymous"
            alt="图片"
            data-fail="0"
          />
        </section>
      </section>
      <p style="">“AI+安防”入围案例颁奖环节&nbsp;&nbsp;</p>
      <section
        style="
          margin-top: 20px;
          margin-right: 0%;
          margin-left: 0%;
          text-align: center;
          justify-content: center;
          display: flex;
          flex-flow: row;
        "
      >
        <section
          style="
            display: inline-block;
            width: 16%;
            vertical-align: top;
            flex: 0 0 auto;
            height: auto;
            border-top: 1px solid rgb(66, 82, 190);
            border-top-left-radius: 0px;
            align-self: flex-start;
          "
        >
          <section style="transform: rotateZ(328deg)">
            <section style="margin-top: -7px; margin-right: 0%; margin-left: 0%">
              <section
                style="
                  display: inline-block;
                  width: 26px;
                  height: 13px;
                  vertical-align: top;
                  overflow: hidden;
                  border-style: solid;
                  border-width: 1px;
                  border-radius: 50%;
                  border-color: rgb(66, 82, 190);
                  background-color: rgba(255, 255, 255, 0);
                "
              >
                <svg
                  viewBox="0 0 1 1"
                  style="float: left; line-height: 0; width: 0; vertical-align: top"
                ></svg>
              </section>
            </section>
          </section>
        </section>
      </section>
    </section>
    <p style="letter-spacing: 0.578px; caret-color: rgba(0, 0, 0, 0); text-align: left">
      <span style="color: rgb(0, 82, 255)"
        ><strong
          data-brushtype="text"
          style="
            letter-spacing: 0.578px;
            caret-color: transparent;
            color: rgb(78, 132, 216);
            font-size: 18px;
          "
          ><strong
            ><span
              style="
                color: rgb(0, 82, 255);
                caret-color: red;
                font-family: 仿宋;
                letter-spacing: 0.578px;
                text-decoration-style: solid;
                text-decoration-color: rgb(78, 132, 216);
              "
              >未来，中码科技将继续秉承<strong
                ><span
                  style="
                    letter-spacing: 0.578px;
                    text-decoration-style: solid;
                    text-decoration-color: rgb(78, 132, 216);
                  "
                  >“客户至上、质量第一</span
                ></strong
              ></span
            ></strong
          ></strong
        ><strong
          data-brushtype="text"
          style="
            letter-spacing: 0.578px;
            caret-color: transparent;
            color: rgb(78, 132, 216);
            font-size: 18px;
          "
          ><strong
            ><span
              style="
                color: rgb(0, 82, 255);
                caret-color: red;
                font-family: 仿宋;
                letter-spacing: 0.578px;
                text-decoration-style: solid;
                text-decoration-color: rgb(78, 132, 216);
              "
              ><strong
                ><span
                  style="
                    color: rgb(0, 82, 255);
                    letter-spacing: 0.578px;
                    text-decoration-style: solid;
                    text-decoration-color: rgb(78, 132, 216);
                  "
                  >”的经营理念</span
                ></strong
              >，不断发展与创新，致力于成长为中国数字化治理服务的顶级解决方案服务运营商与社会安全核验专家，持续不断地为每个客户创造价值。</span
            ></strong
          ></strong
        ></span
      >
    </p>
  </div>
</template>
<style lang="less" scoped>
#js_content_9 {
  .news_bg {
    background-image: linear-gradient(
      90deg,
      rgb(38, 139, 243) 0%,
      rgb(202, 252, 255) 100%
    );
  }
  .title_bg {
    background-image: linear-gradient(
      140deg,
      rgb(28, 96, 248) 34%,
      rgb(30, 101, 248) 66%,
      rgb(50, 151, 248) 100%
    );

    box-shadow: rgb(189, 189, 189) 0px 0px 3px 0px,
      rgb(54, 243, 241) 2px 2px 5px 0px inset, rgb(8, 63, 186) -2px -2px 5px 0px inset;
  }
}
</style>
